import React from 'react';

import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import Container from 'reactstrap/lib/Container';

import { ContentrArea } from '@ttstr/components';

const Faq: React.FC = () => {
  const { t } = useTranslation();

  return (
    <article className="my-5">
      <Container>
        <Helmet>
          <title>FAQ – Fragen und Antworten</title>
        </Helmet>
        <ContentrArea id="faq_1" />
        <ContentrArea id="faq_2" />
        <ContentrArea id="faq_3" />
      </Container>
    </article>
  );
};

export default React.memo(Faq);

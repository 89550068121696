import React from 'react';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';

import { Container } from '@ttstr/components';

const Footer: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Container fluid>
      <Row className="mt-3 mb-3 d-flex align-items-center">
        <Col md="12" className="align-items-end mt-4 text-center text-muted copyright">
          <p className="mb-5">
            &copy; <a href="https://www.fettesbrot.de">FETTES BROT</a>
            <br />
            <small>
              powered by <a href="https://www.tickettoaster.de">tickettoaster Direct-To-Fan Commerce</a>
            </small>
          </p>
        </Col>
        <Col md="4" className="d-none align-items-center justify-content-end mt-4 copyright-logos">
          <a href="https://tickettoaster.de" target="_blank" rel="noreferrer noopener">
            <img
              src={require('@ttstr/assets/images/tt_logo_claim-black.svg')}
              alt="powered by tickettoaster"
              title="powered by tickettoaster"
              loading="lazy"
              className="img-fluid d-block"
            />
          </a>
        </Col>
      </Row>
    </Container>
  );
};

export default React.memo(Footer);

import React from 'react';

import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import { G8Manager } from '@ttstr/components';

import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';

const G8Page: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className="g8-page-content text-center">
      <Helmet>
        <title>{t('G8.TITLE')}</title>
        <body className="g8-page" />
      </Helmet>
      <div className="hero d-flex justify-content-center align-items-center flex-column p-5">
        <img src={require('./assets/images/fb_tape_bs.png')} className="img-fluid mt-3 header-image" alt="" />
        <G8Manager />
      </div>
    </div>
  );
};

export default React.memo(G8Page);
